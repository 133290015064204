import * as React from 'react';

// Layout
import Layout from '../components/layout';

const NotFoundPage = () => {
  return (
    <Layout pageTitle="Adminmate — 404 Page Not Found">
      <>
        <div className="container mx-auto my-40">
          <div className="text-center w-5/6 md:w-4/5 mx-auto">
            <h1 className="text-3xl md:text-5xl lg:text-6xl font-bold leading-tight">Page Not Found</h1>
            <p className="text-xl pt-4 pb-8 text-gray-600">Seems like the page you are looking for doesn't exist or has been moved</p>
          </div>
        </div>
      </>
    </Layout>
  )
}

export default NotFoundPage
